<template>
  <div class="redem-body">
    <div v-if="detail.score < 50">
      <div class="redem-title">
        <div>很遗憾，积分不足，无法兑换!</div>
        <div>Unfortunately, there are not enough points to redeem!</div>
      </div>

      <div class="mvbutton redem-footer" @click="goques(941)">
        <div>放弃体验，继续答题（有机会获得博物馆实物礼品）</div>
        <div>Give up the experience and continue to answer the questions to achieve a museum's physical gift</div>
      </div>
    </div>
    <div v-else>
      <div class="redem-title">
        <div>恭喜你获得体验资格!</div>
        <div>Congratulations on your qualification for the experience!</div>
      </div>

      <div class="mvbutton redem-footer" @click="goques(942)">
        <div>放弃体验，继续答题（有机会获得博物馆实物礼品）</div>
        <div>Give up the experience and continue to answer the questions to achieve a museum's physical gift</div>
      </div>
    </div>
  </div>
</template>
<script>
import api from "@/api/api";
import { mapState, mapGetters, mapMutations, mapActions } from "vuex";
import request from "@/api/request";

export default {
  data() {
    return {
      detail: {},
    };
  },
  methods: {
    ...mapMutations(["setUser"]),
    ...mapGetters(["getUser"]),
    test() {
      api.question(933);
    },
    goques(aid) {
      this.$router.push({
        path: "/question",
        query: {
          aid: aid,
        },
      });
    },
  },
  mounted() {
    api.detail(this.$route.query.acid || 934).then((d) => {
      if (d.data.status == 200) {
        this.detail = d.data.data;
      }
    });
  },
};
</script>


<style scoped lang="less">
.redem-body {
  /*background-color: rgba(214, 201, 172, 1);*/
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  color: #ffffff;
}

.redem-subtitle {
  font-size: 16px;
  margin-top: 30px;
}
.redem-body .redem-title > div:first-child {
  font-size: 24px;
  font-weight: 400;
}
.redem-body .redem-title > div:nth-child(2) {
  font-size: 24px;
}
.redem-body .redem-list {
  box-sizing: border-box;
  padding: 10px;
  margin-top: 20px;
  width: 100%;
}
.redem-body .redem-list .redem-item {
  width: 100%;
  height: 54px;
  background-color: rgba(187, 138, 76, 1);
  border-radius: 4px 0 4px 0;
  box-shadow: 5px 5px 5px rgb(149 104 47);
  margin-bottom: 20px;
  display: flex;
  font-weight: 400;
  align-items: center;
  justify-content: space-between;
  box-sizing: border-box;
  padding: 0 10px;
}
.redem-body .redem-list .redem-item .redem-left {
  font-size: 16px;
  text-align: left;
}
.redem-body .redem-list .redem-item .redem-right {
  font-size: 12px;
}
.redem-body .redem-footer {
  width: 100%;
  height: 73px;
}
.redem-body .redem-list .notice {
  font-size: 16px;
  color: #242020;
  text-align: center;
  padding-bottom: 20px;
}
.redem-body .redem-list .active {
  background-color: rgba(149, 104, 47, 1);
}
.redem-body .redem-list .disabled {
  background-color: rgba(215, 215, 215, 1);
}
</style>
